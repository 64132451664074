import react from 'react'

import { NavButton } from './NavButton'


export function Header() {
  return (
    <div className='fieldset bg-[#8B2635] h-full'>
      <div className='mx-auto text-center w-full md:max-w-screen-lg text-white py-8 pt-16 text-6xl flex flex-col'>
        <h1>David<br /><span className='font-bold'>PUSTKA</span></h1>
        <div className='mt-8 flex flex-col lg:flex-row mx-auto'>
          <NavButton label='O mně' href='#o-omne' />
          <NavButton label='Reference' href='#reference' />
          <NavButton label='Kontakt' href='#kontakt' />
        </div>
        <div className='relative mt-24'><span className="material-symbols-outlined icon text-6xl -ml-8">expand_more</span></div>
      </div>
    </div>
  );
}
import react from 'react'


export function Contact() {
  return (
    <div id='kontakt'>
      <div className='fieldset bg-white h-full mx-auto text-center w-full md:max-w-screen-lg text-black py-16 text-3xl'>
          <h2 className='font-semibold mb-8'>KONTAKT</h2>
          <div className="container flex flex-col gap-4 m-auto">
            <div className='flex flex-row mx-auto gap-2'>
              <span class="material-symbols-outlined">mail</span>
              <span className='text-lg'><a href="mailto:david@pustka.cz">david@pustka.cz</a></span>
            </div>
            <div className='flex flex-row mx-auto gap-2'>
              <span class="material-symbols-outlined">call</span>
              <span className='text-lg'><a href="callto:+420777103226">777 103 226</a></span>
            </div>
            <div className='flex flex-row mx-auto gap-2'>
              <span class="material-symbols-outlined">home</span>
              <span className='text-lg'><a target='_blank' href="https://goo.gl/maps/xK3c9fEfVT3pcFEE8" rel="noreferrer">tř. T.G.Masaryka 1118, 738 01<br className='sm:hidden' /> Frýdek-Místek</a></span>
            </div>
          </div>
      </div>
  </div>
  );
}
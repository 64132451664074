import react from 'react'


export function NavButton(props) {
    const {
        label,
        href
    } = props

  return (
    <a href={href}>
      <button className='border-2 font-semibold rounded-lg px-8 py-2 mx-auto lg:mx-4 border-white text-lg w-52 my-2'>
        {label}
    </button>
    </a>
  );
}
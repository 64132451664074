import react from 'react'

import { Card } from './Card'

export function Refrence() {
  return (
    <div className='fieldset bg-[#8B2635] h-full' id='reference'>
      <div className='mx-auto text-center w-full md:max-w-screen-lg text-white py-16 text-3xl'>
        <h2 className='font-semibold mb-16'>REFERENCE</h2>
        <div className='flex flex-row gap-4 flex-wrap mx-8 justify-center'>
          <Card
            heading='Karla Bijoková - Frýdek - Místek'
            text='Veliká spokojenost, vždy ochotný pomoci v jakékoliv situaci, profesionální jednání, můžu jen doporučit.'
            date='11. dubna 2022'
          />
          <Card
            heading='Veronika Cizlerová - Frýdek - Místek'
            text='Jako vždy - naprostá spokojenost! Ví co dělá, a proč to dělá :). Hlavně poslouchá potřeby klientů a těmi se řídí...Nedělá to pro sebe, ale pro nás, což je velice důležité... Člověk na správném místě... Neposloužil nám poprvé a věřím, že né naposled. Děkuji'
            date='16. února 2022'
          />
          <Card
            heading='Jiří Kubáň'
            text='Pana Pustku můžu jen doporučit. Profesionální přístup, perfektní znalosti, velká ochota ohledně zařizování hypotéky, bezproblémová komunikace.'
            date='26. ledna 2022'
          />
          <Card
            heading='Petr Polednik'
            text='Špičkové a profesionální poradenství. Pan Pustka poskytl maximum informací a variantní řešení. Je to jednička na trhu. Děkuji.'
            date='20. června 2020'
          />
          <Card
            heading='Karel Bartoněk'
            text='Když jsem volal panu Pustkovi poprvé tak mi řekl, že toho má hodně a do konce týdne nemá čas ale i přesto si čas našel a ještě ten večer mi volal a sdělil mi jaké mám možnosti. Po celou dobu byl velice ochotný a profesionální. Opravdu doporučuji ostatním.'
            date='29. října 2019'
            className='hidden md:block'
          />
          <Card
            heading='Jan Foldyna'
            text='Bezproblémová komunikace, rychlost vyřízení, vstřícný, sympatický člověk, který rozumí oborům služeb, které nabízí. Potřebovali jsme zařídit hypotéku a naprostá spokojenost.'
            date='27. února 2019'
            className='hidden md:block'
          />
        </div>
        <div className='mt-8'>
          <p className='text-xs xl:text-sm'>Pro více referencí klikněte na tlačítko níže</p>
          <a href='https://dobryporadce.cz/poradci/ing-david-pustka' target='_blank' className='border-2 font-semibold rounded-lg px-4 py-1 mx-auto lg:mx-4 border-white text-sm w-52 my-2' rel="noreferrer">Více referencí</a>
        </div>
      </div>
    </div>
    
  )
}
import react from 'react'

import logo from '../profile.png'

export function About() {
  return (
    <div id='o-omne'>
      <div className='fieldset bg-white h-full mx-auto text-center w-full md:max-w-screen-lg text-black py-16 text-3xl'>
          <h2 className='font-semibold mb-8'>O MNĚ</h2>
          <div className='flex flex-col sm:flex-row my-4 mx-8 md:mx-12 gap-6 md:gap-8 lg: max-w-4xl'>
            <p className='m:w-4/6 m-auto text-sm'>
              V oblasti financí pracuji od roku 2002. Mé služby zahrnují zprostředkování hypoték a správu majetku (pojištění, spoření, investice). Mým cílem je zhodnocovat ve prospěch klientů své dlouholeté zkušenosti a kontakty v oboru a nacházet vhodná řešení z celého finančního trhu. Zaměřuji se na komplexní servis klientovi - např. u hypotéky to je nejen výběr vhodné banky, ale také pomoc s čerpáním a plněním následných podmínek smlouvy.<br /><br /> Pokud Vás cokoliv z této oblasti zajímá, využijte kontaktů na tomto webu. Rád se s Vámi sejdu v mé frýdecké kanceláři či u vás.
            </p>
            <img src={logo} alt="David Pustka" className='mx-auto rounded-lg w-3/5 sm:w-2/6 aspect-square object-cover object-top' />
          </div>
      </div>
  </div>
  );
}
import react from 'react'


export function Card(props) {
    const {
        heading,
        date,
        text,
        className
    } = props

  return (
    <div className={`w-72 p-2 bg-white rounded-lg text-black text-left ${className}`}>
        <h4 className='font-semibold text-lg text-left p-2 pb-0'>{heading}</h4>
        <div className='flex gap-2'>
            <div className='text-yellow-400 text-xs pl-2 w-fit flex items-center'>
                <span class="material-symbols-outlined star">star</span>
                <span class="material-symbols-outlined star">star</span>
                <span class="material-symbols-outlined star">star</span>
                <span class="material-symbols-outlined star">star</span>
                <span class="material-symbols-outlined star">star</span>
            </div>
            <span className='text-sm italic'>{date}</span>
        </div>
        <p className='text-sm p-2'>{text}</p>
    </div>
  )
}
import './App.css';

import { Header } from './components/Header'
import { About } from './components/About'
import { Refrence } from './components/Refrence'
import { Contact } from './components/Contact'

function App() {
  return (
    <div>
      <Header />
      <About />
      <Refrence />
      <Contact />
    </div>
  );
}

export default App;
